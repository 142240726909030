const Data = [
  [{
    title: 'etno sa',
    data: {
      address: 'Kwidzyńska 11 \n51 - 415 Wrocław',
      law: 'KRS: 0000609877 \nNIP: 894-307-58-20\nRegon: 364093778',
    },
  }],
  [
    {
      title: 'office',
      data: { phone: '71 307 09 95' },
    },
    {
      title: 'investors',
      data: { email: 'inwestorzy@etnocafe.pl' },
    },
  ],
  [{
    title: 'coffee houses',
    data: { link: '/coffee-houses', hash: '' },
  }],
  [{
    title: 'b2b',
    data: { link: '/for-business', hash: 'form' },
  }],
  [{
    title: 'etno zoo',
    data: {
      address: 'Kwidzyńska 11 \n51 - 415 Wrocław',
      law: 'KRS: 0000611833 \nNIP: 894-307-63-05 \nRegon: 364169518',
    },
  }],
  [{
    title: 'hamda',
    data: {
      address: 'Grabiszyńska 235B\n53 - 234 Wrocław',
      law: 'KRS: 0000348674 \nNIP: 726-262-11-61 \nRegon: 100835865',
    },
  }],
];

export default Data;
